import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import UploadDocumentPreview from "./UploadDocumentPreview"

import styles from "./utils/upload.module.scss"

const UploadDropzoneBody = ({
  icon,
  filesUploaded,
  setFilesUploaded,
  state,
  dispatch,
  notifications,
}) => (
  // eslint-disable-next-line
  <div
    className={classNames(
      "mb-0 is-flex is-align-items-center is-justify-content-center",
      styles["dropzone__top"]
    )}
  >
    <p
      className={classNames(
        "title  has-text-primary has-text-centered",
        styles["dropzone__uploadBox"]
      )}
    >
      {filesUploaded.length > 0 ? (
        <UploadDocumentPreview
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          state={state}
          dispatch={dispatch}
          notifications={notifications}
        />
      ) : (
        <Img fixed={icon} alt="fileUpload" />
      )}
    </p>
  </div>
)

export default UploadDropzoneBody
