import React from "react"

import DocumentsCardRow from "../../Cart/DocumentsCard/DocumentsCardRow"

const UploadDocumentPreview = ({
  filesUploaded,
  setFilesUploaded,
  state,
  dispatch,
  notifications,
}) => {
  const handleDeleteDocument = (index) => {
    const { documents } = state
    let documentsList = [...documents]
    documentsList.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...documentsList],
    })
    
    let tempFilesUploaded = [...filesUploaded]
    tempFilesUploaded.splice(index, 1)
    setFilesUploaded(tempFilesUploaded)
  }

  return (
    <div className="mb-1 mt-1">
      {filesUploaded?.map((file, index) => (
        <DocumentsCardRow
          fileName={file.name}
          oldFileName={file.oldname}
          handleDeleteDocument={handleDeleteDocument}
          index={index}
          file={file}
        />
      ))}
    </div>
  )
}

export default UploadDocumentPreview
