import { useStaticQuery, graphql } from "gatsby"

const ALL_BANK_DETAILS = graphql`
  query BankDetails {
    allAirtableBanks {
      nodes {
        data {
          order: Order
          dispensingChannel: Dispensing_Channel
          bankName: Bank_Name
          bankLogo: Bank_Logo {
            localFiles {
              childImageSharp {
                fluid(maxWidth: 300) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          accountName: Account_Name
          accountNumber: Account_Number
          branch: Branch
          accountType: Account_Type
          status: Status
        }
      }
    }
  }
`;

const useBankDetails = () => {
  const data = useStaticQuery(ALL_BANK_DETAILS)
  const bankDetails = data.allAirtableBanks.nodes
  return bankDetails
}

export default useBankDetails;