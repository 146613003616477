import { useStaticQuery, graphql } from "gatsby"

const ALL_PROOF_OF_PAYMENT_IMAGES = graphql`

  {
    dropzone_upload: file(relativePath: { eq: "dropzone__upload.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    proof_of_payment: file(relativePath: { eq: "upload/proof_of_payment.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useProofOfPaymentImages = () => {
  const data = useStaticQuery(ALL_PROOF_OF_PAYMENT_IMAGES)

  return data
}