import React from "react"
import classNames from "classnames"

import styles from "./utils/upload.module.scss"

const UploadErrorMessage = ({ fileName, message }) => (
  <div className={classNames("notification has-text-center mb-2 mx-2 is-size-6 is-warning", styles["uploadNotification"])}>
    {fileName && <span className="has-text-weight-bold">{fileName}</span>}{" "}
    {message}
  </div>
)

export default UploadErrorMessage
