import React, { useState, useContext, useEffect, useRef } from "react"
import classNames from "classnames"

import UploadDropzoneBody from "./UploadDropzoneBody"
import UploadDropzoneLabel from "./UploadDropzoneLabel"
import UploadGuidelinesModal from "./UploadGuidelinesModal"
import UploadErrorMessage from "./UploadErrorMessage"

import { AppContext } from "../../../context/AppContext"
import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "services/zendeskService"
import { uploadDocument } from "./services/uploadDocuments"
import styles from "./utils/upload.module.scss"

// Improvements
// - Merge sliding modal
// - Get high res image for the upload image

const UploadDropzone = ({
  label,
  icon,
  notifications = [],
  filesUploaded,
  setFilesUploaded,
  filesUploadedLimit = 6,
  maxFileSize = 3145728,
  docType,
}) => {
  const { state, dispatch } = useContext(AppContext)
  const fileInputRef = useRef(null)
  const [dropzoneNotifications, setDropzoneNotifications] = useState(notifications)

  const handleShowGuidelines = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        isActive: true,
        isCard: true,
        heading: " ",
        content: (
          <UploadGuidelinesModal handleFileChooser={handleFileChooser} />
        ),
      },
    })
    // Uncomment once sliding modal has been merged
    // dispatch({
    //   type: "SHOW_SLIDING_MODAL",
    //   payload: {
    //     isCard: true,
    //     hideCloseButton: true,
    //     isActive: true,
    //     closeOnClickOutside: true,
    //     content: (
    //       <UploadGuidelinesModal handleFileChooser={handleFileChooser} />
    //     ),
    //   },
    // })
  }

  // Opens the window where you can choose which files to upload
  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  // Changes the data of the files to follow the format needed
  const handleFileRead =  async (event, docType) => {
    setDropzoneNotifications([])
    const tempFilesUploaded = [...event.target.files]
    let initialNotifications = []
    if (tempFilesUploaded.length + state.documents.length > filesUploadedLimit) {
      initialNotifications.push(
        <UploadErrorMessage
          message={`Please upload only a maximum of ${filesUploadedLimit} files.`}
        />
      )
    }
    else {
      let tempFileStorage = [] 
      // Using a tempFileStorage so all the documents can be put into files uploaded at 
      // the same time rather than one at a time which will cause an upload error
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < maxFileSize)
          tempFileStorage.push(await uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            docType,
            handleAlreadyUploaded(setDropzoneNotifications)
          ))
        else
          initialNotifications.push(
            <UploadErrorMessage
              fileName={tempFilesUploaded[i].name}
              message="is greater than 5MB. Please upload a file or photo less than 5MB."
            />
          )
      }
      setFilesUploaded(prevFiles => [...prevFiles, ...tempFileStorage])
    }

    setDropzoneNotifications(prevState => [...prevState, ...initialNotifications])
  }

  // Shows a notif if a file has already been uploaded
  const handleAlreadyUploaded = (setDropzoneNotifications) => (file) => {
    setDropzoneNotifications(prevState => [...prevState, 
      <UploadErrorMessage
        fileName={file.oldname}
        message="has already been uploaded."
      />
    ])
  }

  // Checks if all the documents have tokens already
  useEffect(() => {
    const assignToken = async () => {
      let documentsWithNoToken = filesUploaded?.filter?.((document) => !document?.token)?.length || 0

      if (!state?.activeDocument && documentsWithNoToken > 0) {
        let currentDocumentWithNoToken = filesUploaded?.find?.(
          (document) => !document?.token
        )
        dispatch({
          type: "SAVE_ACTIVE_DOCUMENT",
          payload: currentDocumentWithNoToken,
        })
        await uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
      }
    }
    assignToken()
  }, [filesUploaded])

  // Sets a token for an attachment
  const uploadToZendesk = async ({ currentDocument }) => {
    let documentsWithToken =
    state.documents?.filter?.((document) => !!document?.token)?.length + 1
    
    const UPLOADED_FILES = await zendeskUploadFiles([currentDocument])
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)
    
    let newTempDocument = {
      ...currentDocument,
      token: GENERATED_FILE_TOKEN?.[0],
    }
  
    let currentDocuments = [...filesUploaded]
    currentDocuments = currentDocuments.filter(
      (document) => document?.oldname !== newTempDocument?.oldname
    )
    currentDocuments = [...currentDocuments, newTempDocument]
    
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: currentDocuments,
    })
    
    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })
    
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Successfully uploaded ${documentsWithToken} file${
          documentsWithToken > 1 ? "s" : ""
        }`,
        color: "success",
      },
    })
    
    setFilesUploaded(currentDocuments)
  }

  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={(event) => handleFileRead(event, docType)}
        multiple
        accept="image/*, .pdf, .heic"
        value=""
      />
      <div
        className={classNames("has-background-light", styles["dropzone__container"])}
        role={filesUploaded.length > 0 ? "" : "button"}
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === "Enter" && filesUploaded.length == 0)
            handleFileChooser()
        }}
        onClick={filesUploaded.length === 0 ? handleShowGuidelines : null}
      >
        <UploadDropzoneBody
          icon={icon}
          filesUploaded={filesUploaded}
          setFilesUploaded={setFilesUploaded}
          state={state}
          dispatch={dispatch}
          notifications={dropzoneNotifications}
        />
        {dropzoneNotifications.length > 0 && dropzoneNotifications.map((item) => item)}
        {!filesUploadedLimit || filesUploaded.length < filesUploadedLimit ? (
          <UploadDropzoneLabel
            label={label}
            onKeyDown={(event) => {
              if (event.key === "Enter") handleFileChooser()
            }}
            onClick={
              filesUploaded.length > 0
                ? handleFileChooser
                : handleShowGuidelines
            }
          />
        ) : null}
      </div>
    </div>
  )
}

export default UploadDropzone
