import Resizer from "react-image-file-resizer"
import moment from "moment"

const MINIMUM_SIZE_TO_COMPRESS = 1048576

const generateFileName = (docType) => {
  let type = docType.toLowerCase()

  return `${type}_${moment().format("YYYYMMDD_HHmmss")}`
}

const isAlreadyUploaded = (file, filesUploaded) => {
  return filesUploaded.some((uploaded) => file.path === uploaded.path)
}

export const uploadDocument = async (
  file,
  filesUploaded,
  docType,
  alreadyUploadedCallback
) => {
  let resolvedNewFile = await new Promise(async (resolve, reject) => {
    if (file.type === "application/pdf" || file.type === "image/heic" || file.size < MINIMUM_SIZE_TO_COMPRESS) {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        let newFile = {
          path: reader.result,
          name: await generateFileName(docType),
          filename: await generateFileName(docType),
          oldname: file.name,
          type: docType,
        }
        resolve(newFile)
        if (isAlreadyUploaded(newFile, filesUploaded) && alreadyUploadedCallback) {
          await alreadyUploadedCallback(newFile)
        }
      }
    } else {
      Resizer.imageFileResizer(
        file,
        1000,
        1000,
        "JPEG",
        100,
        0,
        (uri) => {
          let newFile = {
            path: uri,
            name: generateFileName(docType),
            filename: generateFileName(docType),
            oldname: file.name,
            type: docType,
          }
          resolve(newFile)
          if (
            isAlreadyUploaded(newFile, filesUploaded) &&
            alreadyUploadedCallback
          )
            alreadyUploadedCallback(newFile)
        },
        "base64"
      )}
    })
  return await resolvedNewFile
}
