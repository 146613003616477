export const getMedicineTitle = (medicine, hasTwoTitles) => {
  if (hasTwoTitles) {
    const allMedicines = medicine.ProductTitle[0].split(" + ")
    const medicinesTitleArray = allMedicines.map((medicine) => medicine.split("(")[0].trim())
    const medicineTitle = `${medicinesTitleArray[0]} + ${medicinesTitleArray[1]}`
    return medicineTitle
  } else {
    const medicineTitle = medicine.ProductTitle[0].split("(")[0].trim()
    return medicineTitle
  }
}

export const getMedicineBrand = (medicine, hasTwoTitles) => {
  if (hasTwoTitles) { 
    const allMedicines = medicine.ProductTitle[0].split(" + ")
    const medicinesBrandArray = allMedicines.map((medicine) => medicine.split("(")[1].replace(")", ""))
    const medicineBrand = `${medicinesBrandArray[0]} + ${medicinesBrandArray[1]}`
    return medicineBrand
  } else {
    const MedicineBrand = medicine.ProductTitle[0].split("(")[1].replace(")", "")
    return MedicineBrand
  }
}