import React from "react"
import { navigate } from "gatsby"

import Loading from "elements/Loading"

// If there's no Zendesk ID, it redirects to the home page
export const hasZendeskID = (zendeskId) => {
  if (!zendeskId) {
    navigate("/")
    return <Loading />
  }
}

// Gets the current email to compare to the email from order
export const hasCorrectEmail = (
  orderDetails,
  path = "proof-of-payment"
) => {
  const userData = JSON.parse(sessionStorage.getItem("userData"))
  if (orderDetails.email !== userData.email) {
    console.log("invalid email")
    navigate(`/${path}/invalid`)
    return <Loading />
  }
}

// User needs to be on 02 Verified status
// If they are 01, that is an invalid order, and if 03 or above, the order is already verified
export const hasCorrectStatus = (
  orderDetails,
  path = "proof-of-payment"
) => {
  if (!orderDetails.orderStatus) {
    console.log(orderDetails.orderStatus)
    navigate(`/${path}/invalid`)
    return <Loading />
  }
  const { orderStatus } = orderDetails
  const statusNumber = parseInt(orderStatus.substring(0, 2))
  if (statusNumber === 0) {
    navigate(`/${path}/invalid`)
    return <Loading />
  } else if (statusNumber === 1) {
    navigate(`/${path}/order-processing`)
    return <Loading />
  } else if (statusNumber > 2) {
    navigate(`/${path}/verified-order`)
    return <Loading />
  }
}

// If the order is not present, the order is invalid
export const isOrder = (
  orderDetails,
  path = "proof-of-payment"
) => {
  if (!orderDetails.orderStatus) {
    console.log("Not order")
    navigate(`/${path}/invalid`)
    return <Loading />
  }
}
