const getTotals = ({ 
  medicineArray, 
  deliveryFee, 
  hasDiscount 
}) => {
  // Computes for the subtotal. Unit Price x Quantity.
  const subtotal = medicineArray.reduce((accumulator, currentMedicine) => {
    const isEligible = currentMedicine.IsEligible === 1
    if (isEligible) {
      return accumulator + (currentMedicine.UnitPrice[0] * currentMedicine.Quantity)
    } else {
      return accumulator
    }
  }, 0)

  // If there's a discount, then use that in the subtotal
  const discountedSubtotal = medicineArray.reduce((accumulator, currentMedicine) => {
    const isEligible = currentMedicine.IsEligible === 1
    if (isEligible) {
    return accumulator + ((currentMedicine.DiscountedPrice ? currentMedicine.DiscountedPrice : currentMedicine.UnitPrice[0]) * currentMedicine.Quantity)
    } else {
      return accumulator
    }
  }, 0)

  const discount = subtotal - discountedSubtotal

  const orderTotal = (hasDiscount ? discountedSubtotal : subtotal) + deliveryFee

  return {subtotal, discount, orderTotal}
}

export default getTotals